import React from 'react'
import '../components/css/layout.css'
import Seo from '../components/Seo'
import {
  Layout,
  Heading,
  Row,
  Column,
  Section,
  Text,
  UList,
  Anchor,
} from 'components'
import 'components/css/close-account.css'

const CloseAccount = () => (
  <div>
    <Seo
      title="Digipay PH | DigiPadalaAgent"
      description="Digipay is a digital payments and financial services platform with
a mobile wallet, an industry leading biller ecosystem,
and an interoperable backend."
      keywords="digipay.ph/DigiPadalaAgent/,DigiPadalaAgent, DigiPadala, Agent, Requirements, Customer, Customer Requirements, Money Transfer Guide."
    />
    <div id="close-account-section">
      <Layout>
        <Row>
          <Column size="df:[100%] lg:[col-6]" py="sp4" px="sp3" m="auto">
            <Section>
              <Heading
                className="close-account-title"
                mb="sp4"
                fontWeight="bold"
                textAlign="center"
              >
                How to close my Digipay account?
              </Heading>
              <Text className="close-account-text">
                Kindly note that once your Digipay account is closed, you will
                no longer be able to log in using your registered email address
                and/or mobile number.
              </Text>
              <Text className="close-account-text">
                We recommend that you use all of your remaining Digipay top-up
                credits by transacting different Digipay products and services
                before proceeding with account closure.
              </Text>
              <Text className="close-account-text">
                If you would like to close your Digipay account, kindly contact
                us through the following:
              </Text>
              <ul className="list-group">
                <li>Live chat support via Digipay App</li>
                <li>
                  Email:{' '}
                  <Anchor
                    href="https://mail.google.com/mail/?view=cm&fs=1&to=sales.support@digipay.ph"
                    target="_blank"
                  >
                    sales.support@digipay.ph
                  </Anchor>
                </li>
              </ul>
              <Text className="close-account-text">
                You may receive a call from our Customer Support Team within 1-2
                working days to validate your request.
              </Text>
            </Section>
          </Column>
        </Row>
      </Layout>
    </div>
  </div>
)
export default CloseAccount
